// import React, { useState } from "react";
// import "./zoomimage.scss";

// const ZoomableImage = ({ src, alt }: any) => {
//   const [scale, setScale] = useState(1);

//   const zoomIn = () => setScale(scale + 0.1);
//   const zoomOut = () => setScale(scale > 0.1 ? scale - 0.1 : scale);

//   return (
//     <div className="image-container">
//       <img
//         src={src}
//         alt={alt}
//         className="zoomable-image"
//         style={{ transform: `scale(${scale})` }}
//       />
//       <div className="zoom-buttons">
//         <button className="zoom-button" onClick={zoomIn}>
//           +
//         </button>
//         <button className="zoom-button" onClick={zoomOut}>
//           -
//         </button>
//       </div>
//     </div>
//   );
// };

// export default ZoomableImage;

import React, { useState } from "react";
import "./zoomimage.scss";
import ReactDOM from "react-dom";

interface ZoomableImageProps {
  src: string;
  alt: string;
  width?: string | number;
  height?: string | number;
}

const ZoomableImage: React.FC<ZoomableImageProps> = ({ src, alt, width, height }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const openModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsZoomed(true);
  };
    const closeModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsZoomed(false);
  };

  return (
    <>
      <div className="image-container">
        <img
          src={src}
          alt={alt}
          className="zoomable-image"
          onClick={openModal}
        />
      </div>

      {isZoomed && ReactDOM.createPortal(
        <div className="modal">
          <div className="modal-content">
          <span className="close" onClick={closeModal}>
               &times;
          </span>
          <img src={src} alt={alt} className="modal-image" onClick={(e) => e.stopPropagation()}/>
          </div>
        </div>,
        document.body
      )}
    </>
  );
};

export default ZoomableImage;
