import React, { useState, useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";

const LoadingComponent = ({ open }: any) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (open) {
      const totalSteps = 10;
      const delay = 1000;

      const interval = setInterval(() => {
        setPercentage((prevPercentage) => {
          if (prevPercentage < 100) {
            return prevPercentage + 100 / totalSteps;
          }
          clearInterval(interval);
          return prevPercentage;
        });
      }, delay);
    } else {
      setPercentage(0); // Reset the percentage when the overlay is closed
    }
  }, [open]);

  return (
    <div className="loadingOverlay">
      <Backdrop open={open} style={{ zIndex: 999, color: "#fff" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress
            color="inherit"
            variant="determinate"
            value={percentage}
          />
          <div
            style={{
              marginTop: "16px",
              fontSize: "1.2rem",
              fontWeight: "bold",
              color: "#fff",
            }}
          >{`${percentage.toFixed(0)}%`}</div>
        </div>
      </Backdrop>
      {/* <div className="loader">
        <CircularProgress className="example-margin" size={100} />
        <p>{value}%</p>
      </div> */}
    </div>
  );
};

export default LoadingComponent;
