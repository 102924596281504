import React, { useEffect, useState } from "react";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Tooltip,
  IconButton,
  Menu,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

import "./dashboard.scss";

import productsJson from "./sainsbury.json";

import DynamicTable from "../../Components/table/table";

import axios from "axios";
import Dropdown from "../../Components/dropdown/dropdown";
import LoadingComponent from "../../Components/loader/loader";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

// const filters = [
//   "Changed Values",
//   "Unchanged Values",
//   "Marked Accurate",
//   "Marked InAccurate",
// ];

const filters = ["Recommendation", "In Review"];

// const filters = [
//   {
//     id: 1,
//     name: "Changed Values",
//     value: "changed_values",
//   },
//   {
//     id: 2,
//     name: "Unchanged Values",
//     value: "unchanged_values",
//   },
//   {
//     id: 3,
//     name: "Marked Accurate",
//     value: "marked_accurate",
//   },
//   {
//     id: 4,
//     name: "Marked InAccurate",
//     value: "marked_inaccurate",
//   },
// ];

const variants = [
  {
    id: 1,
    name: "Changed Values",
    value: "changed_values",
  },
  {
    id: 2,
    name: "Unchanged Values",
    value: "unchanged_values",
  },
  {
    id: 3,
    name: "Marked Accurate",
    value: "marked_accurate",
  },
  {
    id: 4,
    name: "Marked InAccurate",
    value: "marked_inaccurate",
  },
];

const Dashboard = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<any>([]);
  const [headerColumns, setHeaderColumns] = useState<any>([]);
  const [showHeaderColumns, setShowHeaderColumns] = useState<any>([]);

  const [productTypes, setProductType] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState();

  const [productSubTypes, setproductSubTypes] = useState([]);
  const [selectedSubProductType, setSelectedSubProductType] = useState();

  const [attributes, setAttributess] = useState<any>([]);
  // const [selectedAttribute, setSelectedAttribute] = useState<any>("");
  const [selectedAttribute, setSelectedAttribute] = useState<any[]>([]);

  const [attributeValues, setAttributeValues] = useState<any>([]);
  const [selectedAttributeValue, setSelectedAttributeValue] = useState<any>("");

  const [filterName, setFilterName] = useState<any>("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const [selectedColumns, setSelectedColumns] = useState(
    showHeaderColumns.map((col: any) => col.field)
  );

  const [columns, setColumns] = useState(
    showHeaderColumns.filter((column: any) =>
      selectedColumns.includes(column.field)
    )
  );

  const productsData: any = productsJson;

  useEffect(() => {
    makeStaticDataReady();
  }, [page]);

  // useEffect(() => {
  //   setColumns(
  //     showHeaderColumns.filter((column: any) =>
  //       selectedColumns.includes(column.field)
  //     )
  //   );
  // }, [selectedColumns, showHeaderColumns]);

  const handleColumnSelection = (event: any) => {
    const { value } = event.target;
    setSelectedColumns(value);
  };

  //filters methods
  const handleChange = async (event: any) => {
    setSelectedProductType(event.target.value);
    // setSelectedAttribute("");
    setSelectedAttribute([]);
    setSelectedAttributeValue("");
    setFilterName([]);
  };

  const handleSubProductChange = async (event: any) => {
    setSelectedSubProductType(event.target.value);
    // setSelectedAttribute("");
    setSelectedAttribute([]);
    setSelectedAttributeValue("");
    setFilterName([]);
  };

  // const handleAttributeChange = async (event: any) => {
  //   setSelectedAttribute(event.target.value);
  //   setFilterName([]);
  // };

  const handleAttributeValueChange = async (event: any) => {
    setSelectedAttributeValue(event.target.value);
  };

  const handleAttributeChange = async (event: any) => {
    const { value } = event.target;
    setSelectedAttributeValue("");
    if (value.length === attributes.length && !value.includes("Select All")) {
      setSelectedAttribute([]);
    } else if (
      value.includes("Select All") &&
      value.length < attributes.length
    ) {
      if (value.length === attributes.length - 1) {
        const newValue = attributes.filter(
          (attr: string) => attr !== "Select All" && value.includes(attr)
        );
        setSelectedAttribute(newValue);
      } else {
        setSelectedAttribute([
          "Select All",
          ...attributes.filter((attr: string) => attr !== "Select All"),
        ]);
      }
    } else {
      setSelectedAttribute(value);
    }
    setFilterName([]);
    if (value.length === 1 && !value.includes("Select All")) {
      getProductType(value[0]);
    }

    if (!value.length) {
      getProducts();
    }

    // else {
    //   setAttributeValues("");
    // }
  };

  const handleFilterChangeEvent = (event: any) => {
    // const { value } = event.target;
    // setFilterName(value);
    setFilterName(event.target.value);
  };

  // useEffect(() => {
  //   if (selectedSubProductType) {
  //     getProducts();
  //   }
  // }, [selectedSubProductType, page, rowsPerPage, selectedAttributeValue]);

  // useEffect(() => {
  //   getProductType();
  // }, [selectedProductType]);

  const getProductType = async (attribute?: any) => {
    setIsLoading(true);

    // const params = {
    //   product_type: selectedProductType,
    // };

    let params: any = {
      product_type: selectedProductType,
    };

    if (attribute) params.attribute = attribute;

    try {
      const response = await axios.get(
        "https://ai-retail-macys-validation-api.azurewebsites.net/product-type/",
        {
          params: params,
        }
      );

      if (response.data) {
        setIsLoading(false);
        if (selectedProductType) {
          if (attribute) {
            setAttributeValues(response.data.data);
          } else {
            setproductSubTypes(response.data.data);
          }
          // setproductSubTypes(response.data.data);
        } else {
          setProductType(response.data.data);
        }
      }
    } catch (err: any) {
      setIsLoading(false);
      if (err.response) {
        alert(err.response.data.detail);
      }
    }

    // const response = await axios.get(
    //   "https://ai-retail-macys-validation-api.azurewebsites.net/product-type/",
    //   {
    //     params: params,
    //   }
    // );
  };

  const pageSize = productsData.table_metadata.page_size; // Items per page
  const totalPages = Math.ceil(productsData.table_data.length / pageSize); // Total pages
  const [paginatedData, setPaginatedData] = useState<any>([]); // State to track current page

  // Get the data for the current page

  // Function to handle next button click
  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage);
  };

  // Function to handle previous button click
  const handleRowsPerPageChange = () => {
    if (page > 1) {
      setPage((prev) => prev - 1);
    } else {
      setPage(0);
    }
  };

  // const handlePageChange = (event: any, newPage: number) => {
  //   setPage(newPage);
  // };

  // const handleRowsPerPageChange = (event: any) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const getProducts = async () => {
    setIsLoading(true);
    const params = {
      product_type: selectedProductType,
      product_subtype: selectedSubProductType,
      attributes:
        selectedAttribute &&
        selectedAttribute.length &&
        selectedAttribute[0] == "Select All"
          ? selectedAttribute.slice(1)
          : selectedAttribute,
      attribute_value: selectedAttributeValue,
      attribute_filters: filterName,
      page: `${page + 1}`,
      page_size: rowsPerPage,
    };
    const response = await axios.post(
      "https://ai-retail-macys-validation-api.azurewebsites.net/model-results/",
      params
    );

    // const response = await axios.get(
    //   "https://ai-retail-macys-validation-api.azurewebsites.net/model-results/",
    //   {
    //     params: params,
    //   }
    // );
    try {
      if (response.data) {
        setIsLoading(false);
        if (response.data.table_data) {
          if (response.data.table_data.length) {
          }
          setProducts(response.data.table_data);
        }
        if (response.data.attribute_columns && response.data.default_columns) {
          let headers = [
            ...response.data.default_columns,
            ...response.data.attribute_columns,
          ];
          setHeaderColumns(headers);
          setAttributess(response.data.attribute_columns);

          const modifiedHeaders = headers.map((header) => {
            return {
              field: header,
              header: header
                .replace(/_/g, " ") // Replace underscores with spaces
                .toLowerCase() // Convert the entire string to lowercase first
                .replace(/\b\w/g, (char: string) => char.toUpperCase()), // Capitalize the first letter of each word
            };
            // return header
            //   .replace(/_/g, " ") // Replace underscores with spaces
            //   .toLowerCase() // Convert the entire string to lowercase first
            //   .replace(/\b\w/g, (char: string) => char.toUpperCase()); // Capitalize the first letter of each word
          });
          setShowHeaderColumns(modifiedHeaders);
          setSelectedColumns(modifiedHeaders.map((col: any) => col.field));
        }
        setTotalItems(response.data.table_metadata.total_items);
      }
    } catch (err: any) {
      setIsLoading(false);
      if (err.response) {
        alert(err.response.data.detail);
      }
    }
  };

  const makeStaticDataReady = () => {
    setProducts(productsData.table_data);
    const paginatedData = productsData.table_data.slice(
      page * pageSize,
      (page + 1) * pageSize
    );
    setPaginatedData(paginatedData);
    let headers = [
      ...productsData.default_columns,
      ...productsData.attribute_columns,
    ];
    setHeaderColumns(headers);
    setAttributess(productsData.attribute_columns);

    const modifiedHeaders = headers.map((header) => {
      return {
        field: header,
        header: header
          .replace(/_/g, " ") // Replace underscores with spaces
          .toLowerCase() // Convert the entire string to lowercase first
          .replace(/\b\w/g, (char: string) => char.toUpperCase()), // Capitalize the first letter of each word
      };
      // return header
      //   .replace(/_/g, " ") // Replace underscores with spaces
      //   .toLowerCase() // Convert the entire string to lowercase first
      //   .replace(/\b\w/g, (char: string) => char.toUpperCase()); // Capitalize the first letter of each word
    });
    let showHeaders = modifiedHeaders.map((col: any) => col.field);
    setShowHeaderColumns(modifiedHeaders);
    setSelectedColumns(modifiedHeaders.map((col: any) => col.field));
    setTotalItems(productsData.table_metadata.total_items);
    setColumns(
      modifiedHeaders.filter((column: any) =>
        showHeaders.includes(column.field)
      )
    );
  };

  const filterAndPaginateData = (event: any) => {
    // Filter logic based on the filter type
    let value = event.target.value;
    setFilterName(event.target.value);
    let filteredProducts = productsData.table_data.filter((product: any) => {
      switch (value) {
        case "Recommendation":
          return product.in_review === 1;
        // case "Marked Accurate":
        //   return (
        //     product.Level2_is_accurate ||
        //     product.Level3_is_accurate ||
        //     product.Level4_is_accurate ||
        //     product.Level5_is_accurate
        //   );
        // case "Marked Inaccurate":
        //   return (
        //     product.Level2_is_accurate === false ||
        //     product.Level3_is_accurate === false ||
        //     product.Level4_is_accurate === false ||
        //     product.Level5_is_accurate === false
        //   );
        case "Marked Accurate":
          return Object.keys(product).some(
            (key) => key.endsWith("_is_accurate") && product[key] === true
          );

        case "Marked Inaccurate":
          return Object.keys(product).some(
            (key) => key.endsWith("_is_accurate") && product[key] === false
          );

        default:
          return true; // No filter
      }
    });

    // Pagination logic
    const paginatedData = filteredProducts.slice(
      page * pageSize,
      (page + 1) * pageSize
    );

    // Set the paginated data
    setPaginatedData(paginatedData);

    // Set the total number of items for pagination controls
    setTotalItems(filteredProducts.length);
  };

  const downloadProducts = () => {
    const dataToExport = productsData.table_data.map((item: any) => ({
      Product_ID: item.product_id,
      Image_URL: item.img_url,
      Title: item.initial_title,
      Series_Desc: item.series_desc,
      Level2_Actual: item.Level2_actual,
      Level2_Predicted: item.Level2_predicted,
      Level2_Score: item.Level2_score,
      Level3_Actual: item.Level3_actual,
      Level3_Predicted: item.Level3_predicted,
      Level3_Score: item.Level3_score,
      Level4_Actual: item.Level4_actual,
      Level4_Predicted: item.Level4_predicted,
      Level4_Score: item.Level4_score,
      Level5_Actual: item.Level5_actual,
      Level5_Predicted: item.Level5_predicted,
      Level5_Score: item.Level5_score,
    }));

    // Create a new workbook and a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "ProductData");

    // Download the Excel file
    XLSX.writeFile(workbook, "product_data.xlsx");
  };

  // const downloadProducts = async () => {
  //   setIsLoading(true);
  //   handleMoreClose();
  //   let params: any = {};
  //   params = {
  //     product_type: selectedProductType,
  //     product_subtype: selectedSubProductType,
  //     attributes:
  //       selectedAttribute[0] == "Select All"
  //         ? selectedAttribute.slice(1)
  //         : selectedAttribute,
  //     attribute_filters: filterName,
  //   };
  //   // }
  //   try {
  //     const response = await axios.post(
  //       "https://ai-retail-macys-validation-api.azurewebsites.net/download-data/",
  //       params,
  //       {
  //         responseType: "blob", // This is important for handling binary data
  //       }
  //     );

  //     setIsLoading(false);

  //     // Create a blob from the response data
  //     const blob = new Blob([response.data], { type: "text/csv" });

  //     // Create a link element
  //     const link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = "data.csv"; // The file name

  //     // Append the link to the body
  //     document.body.appendChild(link);

  //     // Programmatically click the link to trigger the download
  //     link.click();

  //     // Remove the link from the document
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     setIsLoading(false);

  //     console.error("Error downloading the file:", error);
  //   }

  //   //
  // };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColumnChange = (event: any, column: string) => {
    setSelectedColumns((prevSelected: any) => {
      if (prevSelected.includes(column)) {
        return prevSelected.filter((col: any) => col !== column);
      } else {
        return [...prevSelected, column];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedColumns.length === showHeaderColumns.length - 3) {
      setSelectedColumns([]);
    } else {
      setSelectedColumns(
        showHeaderColumns.slice(3).map((col: any) => col.field)
      );
    }
  };

  const [moreEl, setMoreEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(moreEl);

  const handleMoreClick = (event: any) => {
    setMoreEl(event.currentTarget);
  };
  const handleMoreClose = () => {
    setMoreEl(null);
  };

  return (
    <div className="dashboard">
      {/* <div
        style={{
          marginBottom: "20px",
          color: "#1976d2",
        }}
      >
        Product Enrichment Tools
      </div> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex", gap: "20px" }}>
          {/* <Dropdown
            label="Product L2"
            value={selectedProductType}
            onChange={handleChange}
            options={productTypes}
            isMandatory={true}
          />
          <Dropdown
            label="Product Type"
            value={selectedSubProductType}
            onChange={handleSubProductChange}
            options={productSubTypes}
            disabled={!selectedProductType}
            isMandatory={true}
          />
          <Dropdown
            label="Attributes"
            value={selectedAttribute}
            onChange={handleAttributeChange}
            // options={attributes}
            options={["Select All", ...attributes]}
            disabled={!selectedProductType}
            onClose={() => {
              if (!selectedAttribute.length && !filterName.length) {
                getProducts();
              }
            }}
            multiple
          />
          <Dropdown
            label="Attribute Value"
            value={selectedAttributeValue}
            onChange={handleAttributeValueChange}
            options={attributeValues}
            disabled={selectedAttribute && selectedAttribute.length > 1}
          /> */}
          <Dropdown
            label="Filter"
            value={filterName}
            // onChange={handleFilterChangeEvent}
            onChange={filterAndPaginateData}
            options={filters}
            // multiple
            disabled={!selectedAttribute}
            // onClose={getProducts}
          />
        </div>
        <div style={{ gap: 10, display: "flex" }}>
          {/* <div>
            <Tooltip title="Select Columns" className="tool-tip">
              <IconButton
                disabled={!selectedSubProductType}
                onClick={handleClick}
              >
                <BuildOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {showHeaderColumns.slice(3).map((col: any) => (
                <MenuItem
                  key={col.field}
                  value={col.field}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleColumnChange(event, col.field);
                  }}
                  sx={{ fontSize: "12px" }}
                >
                  <Checkbox checked={selectedColumns.indexOf(col.field) > -1} />
                  <ListItemText
                    primary={col.header}
                    primaryTypographyProps={{ fontSize: "12px" }}
                  />
                </MenuItem>
              ))}
            </Menu>
          </div> */}
          <Tooltip title="Download" className="tool-tip">
            <IconButton
              disabled={!products.length}
              onClick={() => downloadProducts()}
            >
              <DownloadOutlinedIcon />
            </IconButton>
          </Tooltip>{" "}
        </div>
      </div>

      {products.length > 0 ? (
        <div>
          <DynamicTable
            products={paginatedData}
            headerColumns={headerColumns}
            showHeaderColumns={showHeaderColumns}
            selectedColumns={selectedColumns}
            columns={columns}
            page={page}
            rowsPerPage={pageSize}
            totalItems={totalItems}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            selectedProductType={selectedProductType}
            selectedSubProductType={selectedSubProductType}
          />
        </div>
      ) : selectedProductType && selectedSubProductType ? (
        <div style={{ fontSize: 12 }}>No data to display</div>
      ) : (
        <div style={{ fontSize: 12 }}>
          No data to display
          {/* Please select Product L2 and Product Type to see data */}
        </div>
      )}
      <LoadingComponent open={isLoading} />

      {/* <DataTable /> */}
    </div>
  );
};

export default Dashboard;
