import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TableSortLabel,
  TablePagination,
  Tooltip,
  IconButton,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

import "./table.scss";
import ZoomableImage from "../zoomimage/zoomimage";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import axios from "axios";

const useStyles = makeStyles({
  container: {
    maxHeight: "74vh",
    overflowY: "auto",
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 2,
    backgroundColor: "#fff",
  },
  alternateColor1: {
    backgroundColor: "#f2f2f2", // Color for the first set of columns
  },
  alternateColor2: {
    backgroundColor: "#fff", // Color for the second set of columns
  },
  actualPredictedColor1: {
    backgroundColor: "#f2f2f2", // First color for "Actual" and "Predicted" cells
  },
  actualPredictedColor2: {
    backgroundColor: "#fff", // Second color for "Actual" and "Predicted" cells
  },
  commonStickyClass: {
    backgroundColor: "#fff !important",
    zIndex: 2,
  },
  stickyCellLeft1: {
    position: "sticky",
    left: 0,
    zIndex: 1,
    background: "white",
  },
  stickyCellLeft2: {
    position: "sticky",
    left: 50, // Adjust this value based on your first sticky column width
    zIndex: 1,
    background: "white",
  },
  stickyCellLeft3: {
    position: "sticky",
    left: 100, // Adjust this value based on your second sticky column width
    zIndex: 1,
    background: "white",
  },
  noZIndex: {
    zIndex: "auto !important", // or 'initial'
  },
  stickyCell: {
    zIndex: 1,
  },
});

const DynamicTable = ({
  products,
  headerColumns,
  showHeaderColumns,
  selectedColumns,
  columns,
  page,
  rowsPerPage,
  totalItems,
  onPageChange,
  onRowsPerPageChange,
  selectedProductType,
  selectedSubProductType,
}: any) => {
  const [sortConfig, setSortConfig] = useState<any>({
    key: null,
    direction: "asc",
  });
  const classes = useStyles();

  // const getColumnClass = (index: number) =>
  //   index % 2 === 0 ? classes.alternateColor1 : classes.alternateColor2;
  const getColumnClass = (index: number, isActualOrPredicted: boolean) => {
    if (isActualOrPredicted) {
      // Apply alternating colors for "Actual" and "Predicted" columns
      return index % 2 === 0
        ? classes.actualPredictedColor1
        : classes.actualPredictedColor2;
    } else if (index % 4 === 0 || index % 4 === 3) {
      return classes.alternateColor1; // First alternate color for non-"Actual" and "Predicted" cells
    } else {
      return classes.alternateColor2; // Second alternate color for non-"Actual" and "Predicted" cells
    }
  };

  // const [products, setProducts] = useState(originalProducts)
  const [comments, setComments] = useState<any>({});
  const [accuracies, setAccuracies] = useState<any>({});

  const [checked, setChecked] = useState(false);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    const initialComments: any = {};
    const initialAccuracies: any = {};
    products.forEach((product: any, index: number) => {
      showHeaderColumns.slice(4).forEach((col: any) => {
        initialComments[`${index}_${col.field}_comment`] =
          product[`${col.field}_comment`] || "";
        initialAccuracies[`${index}_${col.field}_is_accurate`] =
          product[`${col.field}_is_accurate`];
      });
    });
    setComments(initialComments);
    setAccuracies(initialAccuracies);
  }, [products, showHeaderColumns]);

  const handleCommentChange = (e: any, index: number, field: any) => {
    setComments({
      ...comments,
      [`${index}_${field.field}_comment`]: e.target.value,
    });
  };

  const updateProduct = async (
    index: number,
    attr: any,
    value: any,
    isFromAccurate: boolean
  ) => {
    let params: any = {
      product_type: selectedProductType,
      product_subtype: selectedSubProductType,
      id: products[index].sku.toString(),
      attribute: attr.field,
    };

    if (isFromAccurate) {
      params.is_accurate = value ? value : "null";
    } else {
      params.comment = value;
    }

    try {
      const response = await axios.put(
        "https://ai-retail-macys-validation-api.azurewebsites.net/model-results/",
        params
      );

      if (response.data) {
        console.log("Success");
      }
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.detail);
      }
    }
  };

  const handleAccuracyChange = (newValue: any, index: any, field: any) => {
    setAccuracies((prev: any) => ({
      ...prev,
      [`${index}_${field.field}_is_accurate`]:
        newValue === accuracies[`${index}_${field.field}_is_accurate`]
          ? null
          : newValue,
    }));

    if (newValue === "y") {
      setComments({
        ...comments,
        [`${index}_${field.field}_comment`]: "",
      });
    }

    //updateProduct(index, field, newValue, true);
  };

  const requestSort = (key: any) => {
    let direction = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const getSortDirection = (field: any) => {
    if (sortConfig.key === field) {
      return sortConfig.direction;
    }
    return false;
  };

  const getSortedPageProducts = (pageProducts: any) => {
    return pageProducts.sort((a: any, b: any) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const paginatedProducts = useMemo(() => {
    const currentPageProducts = products;

    return sortConfig.key
      ? getSortedPageProducts(currentPageProducts)
      : currentPageProducts;
  }, [columns, products, sortConfig]);

  const getButtonStyles = (selectedValue: any, buttonValue: any) => {
    if (selectedValue === buttonValue) {
      return {
        color: buttonValue === "y" ? "green" : "red",
        "&.Mui-selected": {
          color: buttonValue === "y" ? "green" : "red",
          fontWeight: "bold",
        },
        "&:hover": {
          color: buttonValue === "y" ? "darkgreen" : "darkred",
        },
      };
    }
    return {};
  };

  const [removeZIndex, setRemoveZIndex] = useState(false);

  const handleImageClick = () => {
    // setRemoveZIndex(true);
    const stickyCells = document.querySelectorAll(".stickyCell");
    stickyCells.forEach((cell: any) => {
      cell.style.zIndex = "1";
    });
  };

  const handleImageClose = () => {
    // setRemoveZIndex(false);
    const stickyCells = document.querySelectorAll(".stickyCell");
    stickyCells.forEach((cell: any) => {
      cell.style.zIndex = "5";
    });
  };

  const StickyTableCell = styled(TableCell)(({ theme }) => ({
    position: "sticky",
    backgroundColor: theme.palette.background.paper,
    left: 0,
    zIndex: 2,
  }));

  const StickyHeaderCell = styled(TableCell)(({ theme }) => ({
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 3,
  }));

  const DoubleStickyHeaderCell = styled(StickyHeaderCell)({
    left: 0,
    zIndex: 4,
  });

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleMouseEnter = (product_id: any) => {
    setHoveredRow(product_id);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  return (
    <div className="custom-table">
      <div>
        <TableContainer
          component={Paper}
          sx={{ maxHeight: "74vh", maxWidth: "100%", overflow: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow className="table-header-one">
                {columns.slice(0, 1).map((col: any, index: number) => (
                  // <TableCell key={index}>{col}</TableCell>
                  <DoubleStickyHeaderCell
                    key={index}
                    sx={{ left: "0px", backgroundColor: "#fff !important" }}
                  >
                    {col.header}
                  </DoubleStickyHeaderCell>
                ))}
                {columns.slice(1, 2).map((col: any, index: number) => (
                  // <TableCell key={index}>{col}</TableCell>
                  <DoubleStickyHeaderCell
                    key={index}
                    sx={{ left: "76px", backgroundColor: "#fff !important" }}
                  >
                    {col.header}
                  </DoubleStickyHeaderCell>
                ))}
                {columns.slice(2, 3).map((col: any, index: number) => (
                  // <TableCell key={index}>{col}</TableCell>
                  <DoubleStickyHeaderCell
                    key={index}
                    sx={{
                      left: "129px",
                      minWidth: "100px",
                      backgroundColor: "#fff !important",
                    }}
                  >
                    {col.header}
                  </DoubleStickyHeaderCell>
                ))}
                {columns.slice(3, 4).map((col: any, index: number) => (
                  // <TableCell key={index}>{col}</TableCell>
                  <DoubleStickyHeaderCell
                    key={index}
                    sx={{
                      left: "129px",
                      minWidth: "100px",
                      backgroundColor: "#fff !important",
                    }}
                  >
                    {col.header}
                  </DoubleStickyHeaderCell>
                ))}
                {columns.slice(4).map((col: any, index: number) => (
                  <StickyHeaderCell
                    key={index}
                    colSpan={3}
                    align="center"
                    sortDirection={getSortDirection(`${col.field}_predicted`)}
                  >
                    <TableSortLabel
                      active={sortConfig.key === `${col.field}_predicted`}
                      direction={sortConfig.direction}
                      onClick={() => requestSort(`${col.field}_predicted`)}
                    >
                      {col.header}
                    </TableSortLabel>
                  </StickyHeaderCell>
                ))}
              </TableRow>
              <TableRow>
                <DoubleStickyHeaderCell
                  colSpan={1}
                  sx={{ top: "42px", left: "0px" }}
                ></DoubleStickyHeaderCell>
                <DoubleStickyHeaderCell
                  colSpan={1}
                  sx={{ top: "42px", left: "76px" }}
                ></DoubleStickyHeaderCell>
                <DoubleStickyHeaderCell
                  colSpan={1}
                  sx={{ top: "42px", left: "129px", minWidth: "100px" }}
                ></DoubleStickyHeaderCell>
                <DoubleStickyHeaderCell
                  colSpan={1}
                  sx={{ top: "42px", left: "189px", minWidth: "100px" }}
                ></DoubleStickyHeaderCell>
                {columns.slice(4).map((col: any, index: number) => (
                  <Fragment key={index}>
                    <StickyTableCell sx={{ top: "42px" }}>
                      Existing
                    </StickyTableCell>
                    <StickyTableCell sx={{ top: "42px" }}>
                      Predicted
                    </StickyTableCell>
                    <StickyTableCell sx={{ top: "42px" }}>
                      Score
                    </StickyTableCell>
                  </Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedProducts.map((row: any, rowIndex: number) => (
                <Fragment key={rowIndex}>
                  <TableRow
                    onMouseEnter={() => handleMouseEnter(row.product_id)}
                    onMouseLeave={handleMouseLeave}
                    sx={{
                      position: "relative", // Important to position the tooltip correctly
                      "&:hover": {
                        backgroundColor: "red !important", // Optional hover background
                      },
                    }}
                  >
                    {columns.slice(0, 4).map((col: any, colIndex: number) =>
                      (() => {
                        if (col.field === "img_url") {
                          return (
                            <StickyTableCell
                              sx={{ left: "0px" }}
                              className={row.in_review === 1 ? "bg-color" : ""}
                            >
                              <ZoomableImage
                                src={row.img_url}
                                alt={`Product ${row.sku}`}
                              />
                            </StickyTableCell>
                          );
                        } else if (col.field == "product_id") {
                          return (
                            <StickyTableCell
                              sx={{ left: "76px" }}
                              className={row.in_review === 1 ? "bg-color" : ""}
                            >
                              {row.product_id}
                            </StickyTableCell>
                          );
                        } else if (col.field == "initial_title") {
                          return (
                            <StickyTableCell
                              sx={{ left: "76px" }}
                              className={row.in_review === 1 ? "bg-color" : ""}
                            >
                              {row.initial_title}
                            </StickyTableCell>
                          );
                        } else {
                          return (
                            <StickyTableCell
                              sx={{ left: "129px", minWidth: "100px" }}
                              className={row.in_review === 1 ? "bg-color" : ""}
                            >
                              {row.series_desc}
                            </StickyTableCell>
                          );
                        }
                      })()
                    )}
                    {columns.slice(4).map((col: any, colIndex: any) => (
                      <Fragment key={colIndex}>
                        <TableCell
                          // className={`${getColumnClass(
                          //   colIndex * 2 + 3,
                          //   false
                          // )}`}
                          className={
                            row.in_review === 1
                              ? "bg-color"
                              : getColumnClass(colIndex * 2 + 3, false)
                          }
                        >
                          {" "}
                          {row[`${col.field}_actual`] ? (
                            row[`${col.field}_actual`].charAt(0).toUpperCase() +
                            row[`${col.field}_actual`].slice(1).toLowerCase()
                          ) : (
                            <span style={{ color: "#ccc" }}>NA</span>
                          )}
                        </TableCell>
                        <TableCell
                          // className={`${getColumnClass(
                          //   colIndex * 2 + 4,
                          //   false
                          // )}`}
                          className={
                            row.in_review === 1
                              ? "bg-color"
                              : getColumnClass(colIndex * 2 + 3, false)
                          }
                        >
                          {row[`${col.field}_predicted`] ? (
                            row[`${col.field}_predicted`]
                              .charAt(0)
                              .toUpperCase() +
                            row[`${col.field}_predicted`].slice(1).toLowerCase()
                          ) : (
                            <span style={{ color: "#ccc" }}>NA</span>
                          )}
                          <br />
                          <FormControl component="fieldset">
                            <ToggleButtonGroup
                              color="primary"
                              value={
                                accuracies[
                                  `${rowIndex}_${col.field}_is_accurate`
                                ]
                              }
                              exclusive
                              onChange={(e, newValue) =>
                                handleAccuracyChange(newValue, rowIndex, col)
                              }
                              aria-label="Platform"
                              size="small"
                              style={{ marginTop: 6 }}
                              sx={{
                                ".MuiToggleButton-root": {
                                  padding: "4px",
                                  fontSize: 9,
                                  lineHeight: 1,
                                  textTransform: "none",
                                },
                              }}
                            >
                              <ToggleButton
                                value="y"
                                sx={(theme) =>
                                  getButtonStyles(
                                    accuracies[
                                      `${rowIndex}_${col.field}_is_accurate`
                                    ],
                                    "y"
                                  )
                                }
                              >
                                Yes
                              </ToggleButton>
                              <ToggleButton
                                value="n"
                                sx={(theme) =>
                                  getButtonStyles(
                                    accuracies[
                                      `${rowIndex}_${col.field}_is_accurate`
                                    ],
                                    "n"
                                  )
                                }
                              >
                                No
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </FormControl>
                          {accuracies[
                            `${rowIndex}_${col.field}_is_accurate`
                          ] === "n" && (
                            <input
                              className="input-text"
                              type="text"
                              value={
                                comments[`${rowIndex}_${col.field}_comment`]
                              }
                              onChange={(e) =>
                                handleCommentChange(e, rowIndex, col)
                              }
                              // onBlur={(e) =>
                              //   updateProduct(
                              //     rowIndex,
                              //     col,
                              //     e.target.value,
                              //     false
                              //   )
                              // }
                              placeholder="Comments"
                              style={{ marginTop: 6 }}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          className={
                            row.in_review === 1
                              ? "bg-color"
                              : getColumnClass(colIndex * 2 + 3, false)
                          }
                          // className={`${getColumnClass(
                          //   colIndex * 2 + 3,
                          //   false
                          // )}`}
                        >
                          {row[`${col.field}_score`]}
                          {/* {row[`${col.field}_score`] ? (
                            row[`${col.field}_actual`].charAt(0).toUpperCase() +
                            row[`${col.field}_actual`].slice(1).toLowerCase()
                          ) : (
                            <span style={{ color: "#ccc" }}>NA</span>
                          )} */}
                        </TableCell>
                      </Fragment>
                    ))}

                    {/* Conditionally render tooltip text when hovering */}
                    {hoveredRow === row.product_id && (
                      <TableCell
                        colSpan={4} // Spans the entire row
                        sx={{
                          position: "absolute",
                          bottom: "100%", // Adjust the position based on where you want the tooltip to show
                          left: 0,
                          backgroundColor: "rgba(0, 0, 0, 0.75)",
                          color: "white",
                          padding: "10px",
                          borderRadius: "4px",
                          zIndex: 1,
                        }}
                      >
                        {/* The text to show on hover */}
                        <Tooltip title={row["Level5_comment"]} open>
                          <span> {row["Level5_comment"]}</span>
                          {/* <span>{`Details about Product ID: ${["Level5_comment"]}`}</span> */}
                        </Tooltip>
                      </TableCell>
                    )}
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalItems}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        labelRowsPerPage=""
        sx={{ overflow: "hidden" }}
      />
    </div>
  );
};

export default DynamicTable;
