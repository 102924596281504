// Routes.js

import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../Screens/dashboard/dashboard";
import Summary from "../Screens/summary/summary";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/summary" element={<Summary />} />
    </Routes>
  );
};

export default Router;
