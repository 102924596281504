import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TableSortLabel,
  TablePagination,
  Tooltip,
  IconButton,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import "../table/table.scss";
import "./dynamicTable.scss";

const useStyles = makeStyles({
  container: {
    maxHeight: "80vh",
    overflowY: "auto",
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 2,
    backgroundColor: "#fff",
  },
});

// const getBackgroundColor = (percentage: any) => {
//   const green = Math.floor((percentage / 100) * 255);
//   const red = 255 - green;
//   return `rgb(${red}, ${green}, 0)`;
// };

// const getBackgroundColor = (percentage: any) => {
//   const red = Math.floor(((100 - percentage) / 100) * 255);
//   const green = Math.floor((percentage / 100) * 255);
//   return `rgb(${red}, ${green}, 0)`;
// };

const getBackgroundColor = (percentage: any) => {
  const red = Math.min(255, 2 * (100 - percentage));
  const green = Math.min(255, 2 * percentage);
  return `rgb(${red}, ${green}, 0)`;
};

// const getBackgroundColor = (percentage: any) => {
//   const red = Math.min(255, Math.max(0, 255 - Math.round(2.55 * percentage)));
//   const green = Math.min(255, Math.max(0, Math.round(2.55 * percentage)));
//   return `rgb(${red}, ${green}, 0)`;
// };

const CustomTable = ({ stats, headerColumns, orginalJson }: any) => {
  const classes = useStyles();

  return (
    <div className="custom-table dynamic-table" style={{ marginTop: 20 }}>
      <TableContainer component={Paper} className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.stickyHeader}>
            <TableRow>
              {headerColumns.map((col: any, index: number) => (
                <TableCell
                  key={col}
                  // style={{
                  //   backgroundColor:
                  //     orginalJson && orginalJson[col]["color"] === 0
                  //       ? "#fff"
                  //       : orginalJson && orginalJson[col]["color"] === 1
                  //       ? "#f2f2f2"
                  //       : "",
                  // }}
                >
                  {col &&
                    col
                      .replace(/_/g, " ") // Replace underscores with spaces
                      .toLowerCase() // Convert the entire string to lowercase first
                      .replace(/\b\w/g, (char: string) => char.toUpperCase())}
                  {/* {col} */}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stats.map((row: any, rowIndex: number) => (
              <Fragment key={rowIndex}>
                <TableRow>
                  {headerColumns.map((col: any, colIndex: number) => (
                    <TableCell
                      key={col}
                      style={{
                        fontWeight:
                          rowIndex === stats.length - 1 ? "bold" : "normal",
                        backgroundColor:
                          rowIndex === stats.length - 1 ? "#f2f2f2" : "",
                        maxWidth: col === "description" ? "200px" : "",
                      }}
                      // style={{
                      //   fontWeight:
                      //     rowIndex === stats.length - 1 ? "bold" : "normal",
                      //   backgroundColor:
                      //     rowIndex === stats.length - 1
                      //       ? "#f2f2f2"
                      //       : orginalJson &&
                      //         orginalJson[col]["color"] === 0 &&
                      //         orginalJson[col]["type"] === null
                      //       ? "#fff"
                      //       : orginalJson &&
                      //         orginalJson[col]["color"] === 1 &&
                      //         orginalJson[col]["type"] === null
                      //       ? "#f2f2f2"
                      //       : orginalJson && orginalJson[col]["type"] === 1
                      //       ? getBackgroundColor(row[col])
                      //       : "",
                      // }}
                    >
                      {colIndex === 0
                        ? row[col].charAt(0).toUpperCase() +
                          row[col].slice(1).toLowerCase()
                        : row[col]}
                    </TableCell>
                  ))}
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CustomTable;
