import React, { useEffect, useState } from "react";
import axios from "axios";
import "../dashboard/dashboard.scss";
import CustomTable from "../../Components/dynamicTable/dynamicTable";

import descriptionJson from "./sainsburyDescription.json";

const Summary = () => {
  const data: any = descriptionJson;

  const stats = data.data;
  const tableHeader = data.columns;

  return (
    <div className="dashboard">
      <CustomTable
        stats={stats}
        headerColumns={tableHeader}
        orginalJson={data}
      />
    </div>
  );
};

export default Summary;
