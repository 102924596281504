import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  menuItem: {
    padding: "4px 8px", // Adjust these values to reduce space
  },
});

const Dropdown = ({
  label,
  value,
  onChange,
  onClose,
  options,
  disabled,
  multiple,
  isMandatory,
}: any) => {
  return (
    <FormControl
      className="dropdown"
      style={{ width: "200px", borderRadius: 8 }}
    >
      <InputLabel
        sx={{
          "&.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-root": {
            fontSize: "12px",
            top: "-3px",
          },
        }}
      >
        {label} {isMandatory ? <span style={{ color: "red" }}>*</span> : ""}
      </InputLabel>
      <Select
        multiple={multiple}
        value={value}
        onChange={onChange}
        onClose={onClose}
        input={multiple ? <OutlinedInput label={label} /> : undefined}
        renderValue={multiple ? (selected) => selected.join(", ") : undefined}
        // renderValue={multiple ? (selected) => selected.join(", ") : undefined}
        sx={{
          height: "40px", // Adjust the height as per your requirement
          padding: "10px", // Adjust the padding as per your requirement
          ".MuiOutlinedInput-input": {
            padding: "10px",
            fontSize: "12px",
          },
          ".MuiSelect-selectMenu": {
            display: "flex",
            alignItems: "center", // Vertically center content
            textAlign: "center", // Center the placeholder text and selected value
          },
          ".MuiTypography-root": {
            fontSize: "12px",
          },
        }}
        disabled={disabled}
        label={label}
      >
        {options.map((option: any) => (
          <MenuItem key={option} value={option} sx={{ fontSize: "12px" }}>
            {multiple && <Checkbox checked={value.indexOf(option) > -1} />}
            <ListItemText
              primaryTypographyProps={{ fontSize: "12px" }}
              primary={
                option &&
                option
                  .replace(/_/g, " ") // Replace underscores with spaces
                  .toLowerCase() // Convert the entire string to lowercase first
                  .replace(/\b\w/g, (char: string) => char.toUpperCase())
                // option.charAt(0).toUpperCase() + option.slice(1).toLowerCase()
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
