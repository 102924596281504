import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";

import { NavLink, useNavigate } from "react-router-dom";

import "./header.scss";

const pages = [
  { title: "Home", link: "/" },
  { title: "Description", link: "/summary" },
];

function ResponsiveAppBar() {
  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ minHeight: "32px", padding: "0 8px" }}>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            <div
              style={{
                marginRight: "20px",
                color: "#1976d2",
                fontWeight: "bold",
                fontSize:"20px",
              }}
            >
              {" "}
              ai.Retail Product Categorization
            </div>
            {pages.map((page) => (
              <NavLink
                key={page.title}
                to={page.link}
                className="link"
                style={({ isActive }) => ({
                  my: 1,
                  mx: 1,
                  //   textDecoration: isActive ? "underline" : "none",
                  fontWeight: isActive ? "bold" : "normal",
                  //   boxShadow: isActive ? "0px 0px 4px" : "none",
                  //   border: isActive ? "0.5px solid" : "none",
                  backgroundColor: isActive ? "#ccc" : "transparent",
                  padding: "4px 16px",
                  //   minHeight: "32px",
                  display: "block",
                  color: "#000",
                  textDecoration: "none",
                })}
              >
                {page.title}
              </NavLink>
              //   <Button
              //     key={page.title}
              //     onClick={() => navigate(page.link)}
              //     sx={{
              //       my: 1,
              //       mx: 1,
              //       color: "white",
              //       padding: "4px 8px",
              //       minHeight: "32px",
              //       display: "block",
              //       fontSize: "12px",
              //       textTransform: "none",
              //       margin: 0,
              //     }}
              //   >
              //     {page.title}
              //   </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
